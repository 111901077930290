import React, { useEffect, useState } from "react";



const ParticularExamResult = ({data,examId}) => {


	return (
		<>
			{
				data.length>0?
					<div className="w-full grid">
						{/*<input*/}
						{/*	className="input input-bordered ml-auto m-1  !z-40 rounded-[2px] input-sm  "*/}
						{/*	type="text"*/}
						{/*	placeholder="Search by phone"*/}
						{/*	value={search}*/}
						{/*	onChange={(event) => setSearch(event.target.value)}*/}
						{/*/>*/}
						<table className="table   border-separate w-full table-zebra  table-auto table-compact ">
							<thead className="border-b sticky top-0">
							<tr>
								<th className="bg-[#50DBB4] !relative	text-center pl-5 pr-5 ">User_Id</th>
								<th className="bg-[#50DBB4] !relative !w-20	text-center pl-5 pr-5 ">Name</th>
								<th className="bg-[#50DBB4] !relative	text-center pl-5 pr-5 ">Phone</th>
								<th className="bg-[#50DBB4]  !w-20 text-center">Exam Name</th>
								<th className="bg-[#50DBB4] w-full text-center">Set Name</th>
								<th className="bg-[#50DBB4] w-full text-center">TQ</th>
								<th className="bg-[#50DBB4] w-full text-center">Correct</th>
								<th className="bg-[#50DBB4] w-full text-center">Wrong</th>
								<th className="bg-[#50DBB4] w-full text-center">Blank</th>
								<th className="bg-[#50DBB4] w-full text-center">Marks</th>
								<th className="bg-[#50DBB4] w-full text-center">Result Date</th>
							</tr>
							</thead>
							<tbody>
							{data.map((e, id) => (
								<tr key={id} >
									<td className="z-0">
										{e.user_id}
									</td>
									<td className="z-0">
										{e.user_name.substring(0,20)}
									</td>
									<td className="z-0">
										{e.phone}
									</td>
									<td className="z-0">
										{e.exam_name}
									</td>
									<td className="z-0">
										{e.set_name}
									</td>
									<td className="z-0">
										{parseInt(JSON.parse(e.correct_wrong_blank).correct)+parseInt(JSON.parse(e.correct_wrong_blank).wrong)+parseInt(JSON.parse(e.correct_wrong_blank).blank)}
									</td>
									<td className="z-0">
										{JSON.parse(e.correct_wrong_blank).correct}
									</td>
									<td className="z-0">
										{JSON.parse(e.correct_wrong_blank).wrong}
									</td>
									<td className="z-0">
										{JSON.parse(e.correct_wrong_blank).blank}
									</td>
									<td className="z-0">
										{e.marks}
									</td>
									<td className="z-0">
										{e.date}
									</td>
								</tr>
							))}
							</tbody>
						</table>
					</div>:<p>No Data Found</p>

			}
		</>
	);
};

export default ParticularExamResult;
