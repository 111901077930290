import React, { createRef } from "react";
import { API_BASE_URL } from "../utils/config";

const VideoUpload = () => {
	const fileInput = createRef();

	const handleUpload = async (e) => {
		e.preventDefault();
		const formData = new FormData();
		formData.append("file", fileInput.current.files[0]);
		try {
			const res = await fetch(`${API_BASE_URL}/upload-video`, {
				method: "POST",
				headers: {
					'Authorization': 'Bearer ' + "6A586E3272357538782F413F4428472B4B6250655368566D5970337336763979"
				},
				body: formData,
			});
			const data = await res.json();
			if (res.ok) {
				// Handle successful upload
			}
		} catch (e) {
			console.error(e);
		}
	};

	return (
		<>
			<form onSubmit={handleUpload}>
				<input
					type="file"
					className="file-input file-input-bordered w-full max-w-xs"
					name="videoFile"
					ref={fileInput}
				/>
				<button type="submit" className="btn btn-sm bg-neutral">
					Submit
				</button>
			</form>
		</>
	);
};

export default VideoUpload;
