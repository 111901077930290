import React, {useEffect, useRef, useState} from "react";
import axios from "axios";
import {getUserData} from "../../../utils/LocalHandler";
import {AiFillCheckCircle, AiFillDelete, AiFillEdit, AiOutlinePlusCircle} from "react-icons/ai";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {RxCross2} from "react-icons/rx";
import LoadingTableComponent from "../../loadingTable.component";
import {API_BASE_URL} from "../../../utils/config";
import {toast} from "react-toastify";
import {App_Name} from "../../../utils/name";
import Swal from "sweetalert2";
import {SiPlanetscale} from "react-icons/si";
import {BsStopwatch} from "react-icons/bs";
import {BiRefresh} from "react-icons/bi";

const Main= (props) => {

    const [isLoading, setIsLoading] = useState(true);

    const [searchQuery, setSearchQuery] = useState('');
    const [data, setData] = useState([]);

    const [createTitle,setcreateTitle]=useState()
    const [setId,setSetId]=useState()

    const navigate = useNavigate()

    const closeRef=useRef()
    const closeRef1=useRef()

    const [qid, setQid] = useState('');
    const [title, setTitle] = useState('');
    const [seoJson, setSeoJson] = useState();
    const [updateSetId,setUpdateSetId]=useState()
    const [logo, setLogo] = useState('');
    const [showPreviousYearTag, setShowPreviousYearTag] = useState();
    const [pdfUrl, setPdfUrl] = useState('');
    const [allowShare, setAllowShare] = useState();
    const [setPassword, setSetPassword] = useState('');
    const [perQuestionTime, setPerQuestionTime] = useState();
    const [positiveMark, setPositiveMark] = useState();
    const [negativeMark, setNegativeMark] = useState();
    const [sortingParams, setSortingParams] = useState();
    const [visibility, setVisibility] = useState();

    async function fetchData() {
        const userDetails = getUserData();
        const token = userDetails.token;
        const configData = {
            token,
        };
        const response = await axios({
            url: `${API_BASE_URL}/get-live_quiz`,
            method: "post",
            data: configData
        });
        let data1 = response['data'];
        setData(data1.data);
        setIsLoading(false)
        // console.log(data1.data)
        // if (data1.status == 500 || data1.status == 401) return navigate("/login")
    }

    useEffect(() => {
        fetchData();
    }, []);


    const filteredItems = data.filter((qu) =>
        qu.title.toLowerCase().includes(searchQuery.toLowerCase())
    );

const handleUpdate=(id)=>{

    setQid(filteredItems[id].id);
    setTitle(filteredItems[id].title);
    setUpdateSetId(filteredItems[id].set_id)
    setSeoJson(filteredItems[id].seo_json);
    setLogo(filteredItems[id].logo);
    setShowPreviousYearTag(filteredItems[id].show_previous_year_tag);
    setPdfUrl(filteredItems[id].pdf_url);
    setAllowShare(filteredItems[id].allow_share);
    setSetPassword(filteredItems[id].set_password);
    setPerQuestionTime(filteredItems[id].per_question_time);
    setPositiveMark(filteredItems[id].positive_mark);
    setNegativeMark(filteredItems[id].negative_mark);
    setSortingParams(filteredItems[id].sorting_params);
    setVisibility(filteredItems[id].visibility);

    closeRef1.current.click()
}

    const updateNow = async (e) => {
e.preventDefault()
        debugger
        const userDetails = getUserData();
        const token = userDetails.token;

        const liveTestCreatingToast = toast.warning('updating...', {
            position: toast.POSITION.TOP_RIGHT,
            theme: "colored",
            hideProgressBar: true,
            isLoading: true
        });

        let options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                token: token,
                id: qid,
                title: title,
                seo_json: null,
                logo: logo,
                set_id: updateSetId,
                show_previous_year_tag: showPreviousYearTag,
                pdf_url: pdfUrl,
                allow_share: allowShare,
                set_password: setPassword,
                per_question_time: perQuestionTime,
                positive_mark: positiveMark,
                negative_mark: negativeMark,
                sorting_params: sortingParams,
                visibility: visibility,
            }),
        };
        const response = await fetch(
            `${API_BASE_URL}/update-live-quiz`,
            options
        );
        let resData = await response.json();
        // console.log(resData);
        if (resData["status"] === 200) {
            toast.update(liveTestCreatingToast, {
                render: "Resolved successfully", autoClose: 3000,
                type: "success", isLoading: false
            });
            setData(resData['data'])
        }
    }


    const handleAdd = async (e, id) => {
        e.preventDefault();
        const userDetails = getUserData();
        const token = userDetails.token;
        const liveTestCreatingToast = toast.warning('Creating...', {
            position: toast.POSITION.TOP_RIGHT,
            theme: "colored",
            hideProgressBar: true,
            isLoading: true
        });
            closeRef.current.click();
            let options = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    token: token,
                    title: createTitle,
                    set_id: setId
                }),
            };
            const response = await fetch(
                `${API_BASE_URL}/create-live-quiz`,
                options
            );
            let resData = await response.json();
            // console.log(resData);
            if (resData["status"] === 404) {


            }
            else if (resData["status"] === 200) {
                setData(resData["data"])
                toast.update(liveTestCreatingToast, {
                    render: "Created successfully", autoClose: 3000,
                    type: "success", isLoading: false
                });
                setcreateTitle("")
                setId("")

            }
    }

    const handleRemove= async (id)=> {
            Swal.fire({
                title: "<h1>Write <b>delete</b> to delete</h1>"+filteredItems[id].title,
                text:'This is permanent delete',
                input: 'text',
                inputAttributes: {
                    autocapitalize: 'off'
                },
                showCancelButton: false,
                confirmButtonText: 'delete',
                showLoaderOnConfirm: true,
                confirmButtonColor: 'red',
                inputValidator: async (value) => {

                    if (!value) {
                        toast.error( { render: "Type delete to delete", autoClose: 3000,
                            type: "error" });
                    } else if (value.toLowerCase() === 'delete') {
                        Swal.close();
                        const userDetails = getUserData();
                        const token = userDetails.token;
                        const liveTestCreatingToast = toast.warning('deleting...', {
                            position: toast.POSITION.TOP_RIGHT,
                            theme: "colored",
                            hideProgressBar: true,
                            isLoading: true
                        });
                        // console.log("Exam id:"+filteredItems[id].exam_id ,"Exam Name: "+ filteredItems[id].exam_name)
                        let options = {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                            },
                            body: JSON.stringify({
                                token: token,
                                id : filteredItems[id].id,
                            }),
                        };
                        const response = await fetch(
                            ` ${API_BASE_URL}/delete-live-quiz`,
                            options
                        );
                        let resData = await response.json();
                        console.log(resData);
                        if (resData["status"] === 200) {
                            setData(resData["data"])
                            toast.update(liveTestCreatingToast, { render: "deleted successfully", autoClose: 3000,
                                type: "success", isLoading: false });
                        }

                    }
                    else{
                        Swal.close();
                        toast.error( { render: "Type delete to delete", autoClose: 3000,
                            type: "error" });
                        toast.error('Type delete to delete', {
                            autoClose: 3000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    }
                }
            })
    }

    async function handleRefresh(e) {
        e.preventDefault();
        const liveTestCreatingToast = toast.warning('Refreshing...', {
            position: toast.POSITION.TOP_RIGHT,
            theme: "colored",
            hideProgressBar: true,
            isLoading: true
        });
        let options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                password:'bigbooster-update-quiz'
            }),
        };
        const response = await fetch(
            `https://quiz.bigbooster.in/update-live-quiz-data`,
            options
        );
        let resData = await response.json();

        toast.update(liveTestCreatingToast, {
            render: `${resData["message"]}`, autoClose: 3000,
            type: "success", isLoading: false
        });
    }

    return (
        <>

            <div className="bg-base-100 !p-4 m-0 flex flex-col h-screen ">
                {/* Header */}
                <header>
                    <div className=" mx-auto">
                        <div className="grid  grid-cols-3 mb-2">
                            <div className="col-span-2">
                                <Link to="/"
                                      className="mr-1 max-sm:ml-0 max-sm:mt-1 capitalize btn-warning btn  !rounded-[2px]  max-sm:text-sm max-sm:btn-sm"> {App_Name}</Link>
                                <button
                                    className=" btn bg-gray-300  btn-ghost !rounded-[2px] font-bold max-sm:text-sm max-sm:btn-sm">Live
                                    Live Quizzes {data.length}</button>

                            </div>
                            <div className="justify-self-end">
                                <div className="form-control ">
                                    <div className="rounded  flex">
                                        <button onClick={handleRefresh} className="btn btn-ghost mr-1 !rounded-[2px] text-3xl max-sm:text-xl max-sm:btn-sm"><BiRefresh/></button>
                                        <input
                                            className="input input-bordered rounded-[2px]  mr-1 max-sm:input-xs max-sm:w-28"
                                            type="text"
                                            placeholder="Search "
                                            value={searchQuery}
                                            onChange={(event) => setSearchQuery(event.target.value)}
                                        />
                                        <label htmlFor="createQuizModal"
                                               className="btn btn-ghost ml-1  bg-gray-300  !rounded-[2px] text-3xl max-sm:btn-sm"><AiOutlinePlusCircle/></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>

                {/* Middle container */}

                <main className="flex-1 overflow-y-auto overflow-auto scrollbar-thin  ">
                    <div className="h-[90%] overflow-x-scroll overflow-y-scroll">
                        {
                            !isLoading?<table className="border-separate w-full table-zebra table  table-compact ">
                                <thead className="border-b sticky top-0">
                                <tr>
                                    {/*<th className="bg-[#50DBB4] pl-2 pr-2 "></th>*/}
                                    <th className="bg-[#50DBB4] pl-6 pr-6 text-center">Logo</th>
                                    <th className="bg-[#50DBB4] pl-2 pr-2 text-center">Title</th>
                                    <th className="bg-[#50DBB4] pl-2 pr-2 text-center">Set Id</th>
                                    <th className="bg-[#50DBB4] text-center">Set Psw</th>
                                    <th className="bg-[#50DBB4] text-center">Sorting Param</th>
                                    <th className="bg-[#50DBB4] text-center">P.M</th>
                                    <th className="bg-[#50DBB4] text-center">N.M</th>
                                    <th className="bg-[#50DBB4] text-center">Previous Year Tag</th>
                                    <th className="bg-[#50DBB4] text-center">Per Question Time</th>
                                    <th className="bg-[#50DBB4] text-center">Pdf</th>
                                    <th className="bg-[#50DBB4] text-center">Allow Share</th>
                                    <th className="bg-[#50DBB4] text-center">Visibility</th>
                                    <th className="bg-[#50DBB4] text-center">Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                { filteredItems.map((e, id) => (
                                    <tr key={id} >
                                        {/*<th>{id + 1}</th>*/}
                                        <th >
                                            {
                                                <img className="h-[28px] w-[50px] ml-auto mr-auto  rounded-[5px]" src={e.logo}   />
                                            }
                                        </th>
                                        <td >
                                            {
                                                e.title
                                            }
                                        </td>
                                        <td >
                                            {
                                                e.set_id
                                            }
                                        </td>
                                        <td >
                                            {
                                                e.set_password
                                            }
                                        </td>
                                        <td >
                                            {
                                                e.sorting_params
                                            }
                                        </td>
                                        <td >
                                            {
                                                e.positive_mark
                                            }
                                        </td>
                                        <td >
                                            {
                                                e.negative_mark
                                            }
                                        </td>
                                        <td >
                                            {
                                                <input type="checkbox" className="toggle toggle-accent" checked={e.show_previous_year_tag == "1"? true : false} />
                                            }
                                        </td>
                                        <td >
                                            {
                                                e.per_question_time
                                            }
                                        </td>
                                        <td >
                                            {
                                                e.pdf_url?<a href={e.pdf_url}>click</a>:null
                                            }
                                        </td>
                                        <td >
                                            {
                                                <input type="checkbox" className="toggle toggle-accent" checked={e.allow_share == "1"? true : false} />
                                            }
                                        </td>
                                        <td >
                                            {
                                                <input type="checkbox" className="toggle toggle-accent" checked={e.visibility == "1"? true : false} />
                                            }
                                        </td>
                                        <td >
                                            <div className="btn-group gap-2">

                                                    <button
                                                        className="btn btn-sm  btn-outline !rounded-[2px]"
                                                        onClick={() => handleUpdate(id)}
                                                    >
                                                        {/*<AiFillEdit/>*/} Edit
                                                    </button>

                                                    <button
                                                    className="btn btn-sm btn-error  btn-outline !rounded-[2px]"
                                                    onClick={() => handleRemove(id)}
                                                    >
                                                    Delete
                                                    </button>

                                            </div>
                                        </td>
                                    </tr>
                                )) }
                                </tbody>
                            </table>:<LoadingTableComponent/>
                        }
                    </div>
                </main>

            </div>


            <input type="checkbox" id="createQuizModal" className="modal-toggle"/>
            <label htmlFor="createQuizModal" className="modal cursor-pointer">
                <label className="modal-box relative" htmlFor="">
                    <div className="grid grid-cols-1">
                        <div>
                            <p className="text-lg"><b>Create</b></p>
                        </div>
                        <div className="justify-self-end mt-[-30px]">
                            <label ref={closeRef} htmlFor="createQuizModal"
                                   className="btn justify-self-end btn-ghost btn-sm ">
                                <RxCross2/>
                            </label>
                        </div>
                        <form onSubmit={handleAdd}>
                            <div className="form-control w-full">
                                <label className="label">
                                    <span className="label-text">Title</span>
                                </label>
                                <input type="text" placeholder="Title"
                                       className="input rounded-[5px] input-bordered text-sm   c-font1"
                                       value={createTitle} onChange={(e) => {
                                    setcreateTitle(e.target.value);
                                }}/>
                            </div>
                            <div className="form-control ">
                                <label className="label">
                                    <span className="label-text">Set ID</span>
                                </label>
                                <input type="text" placeholder="Set Id"
                                       className="input rounded-[5px] input-bordered text-sm  c-font1"
                                       value={setId} onChange={(e) => {
                                    setSetId(e.target.value);
                                }}/>
                            </div>
                            <button className="btn btn-n w-full capatilize rounded-[5px] mt-4">Submit</button>
                        </form>
                    </div>

                </label>
            </label>

            <input type="checkbox" id="editQuizModal" className="modal-toggle"/>
            <label htmlFor="editQuizModal" className="modal cursor-pointer">
                <label className="modal-box relative" htmlFor="">
                    <div className="grid grid-cols-1">
                        <div>
                            <p className="text-lg"><b>Update: </b>{title}</p>
                            {/*    titile*/}
                        </div>
                        <div className="justify-self-end  mt-[-30px]">
                            <label ref={closeRef1} htmlFor="editQuizModal"
                                   className="btn justify-self-end btn-ghost btn-sm ">
                                <RxCross2/>
                            </label>
                        </div>
                        <hr/>
                    </div>
                    <form onSubmit={updateNow}>
                        <div className="form-control w-full">
                            <label className="label">
                                <span className="label-text">Title</span>
                            </label>
                            <input
                                type="text"
                                placeholder="Title"
                                className="input rounded-[5px] input-bordered text-sm c-font1"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                            />
                        </div>
                        {/*<div className="form-control w-full">*/}
                        {/*    <label className="label">*/}
                        {/*        <span className="label-text">SEO JSON</span>*/}
                        {/*    </label>*/}
                        {/*    <textarea*/}
                        {/*        placeholder="SEO JSON"*/}
                        {/*        className="textarea rounded-[5px] textarea-bordered text-sm c-font1"*/}
                        {/*        value={JSON.stringify(seoJson)}*/}
                        {/*        onChange={(e) => setSeoJson(JSON.parse(e.target.value))}*/}
                        {/*        ref={seoJsonInputRef}*/}
                        {/*    />*/}
                        {/*</div>*/}
                        <div className="form-control w-full">
                            <label className="label">
                                <span className="label-text">Logo</span>
                            </label>
                            <input
                                type="text"
                                placeholder="Logo"
                                className="input rounded-[5px] input-bordered text-sm c-font1"
                                value={logo}
                                onChange={(e) => setLogo(e.target.value)}
                            />
                        </div>
                        <div className="form-control w-full">
                            <label className="label">
                                <span className="label-text">Show Previous Year Tag</span>
                            </label>
                            <select
                                className="select w-full select-bordered c-font1 !rounded-[5px]"
                                value={showPreviousYearTag}
                                onChange={(event) => {
                                    setShowPreviousYearTag(event.target.value);
                                }}
                            >
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                            </select>
                        </div>
                        <div className="form-control w-full">
                            <label className="label">
                                <span className="label-text">PDF URL</span>
                            </label>
                            <input
                                type="text"
                                placeholder="PDF URL"
                                className="input rounded-[5px] input-bordered text-sm c-font1"
                                value={pdfUrl}
                                onChange={(e) => setPdfUrl(e.target.value)}
                            />
                        </div>
                        <div className="form-control w-full">
                            <label className="label">
                                <span className="label-text">Allow Share</span>
                            </label>
                            <select
                                className="select w-full select-bordered c-font1 !rounded-[5px]"
                                value={allowShare}
                                onChange={(event) => {
                                    setAllowShare(event.target.value);
                                }}
                            >
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                            </select>
                        </div>
                        <div className="form-control w-full">
                            <label className="label">
                                <span className="label-text">Set Id</span>
                            </label>
                            <input
                                type="text"
                                placeholder="Set Password"
                                className="input rounded-[5px] input-bordered text-sm c-font1"
                                value={updateSetId}
                                onChange={(e) => setUpdateSetId(e.target.value)}
                            />
                        </div>
                        <div className="form-control w-full">
                            <label className="label">
                                <span className="label-text">Set Password</span>
                            </label>
                            <input
                                type="text"
                                placeholder="Set Password"
                                className="input rounded-[5px] input-bordered text-sm c-font1"
                                value={setPassword}
                                onChange={(e) => setSetPassword(e.target.value)}
                            />
                        </div>
                        <div className="form-control w-full">
                            <label className="label">
                                <span className="label-text">Per Question Time</span>
                            </label>
                            <input
                                type="number"
                                placeholder="Per Question Time"
                                className="input rounded-[5px] input-bordered text-sm c-font1"
                                value={perQuestionTime}
                                onChange={(e) => setPerQuestionTime(parseInt(e.target.value))}
                            />
                        </div>
                        <div className="form-control w-full">
                            <label className="label">
                                <span className="label-text">Positive Mark</span>
                            </label>
                            <input
                                type="number"
                                placeholder="Positive Mark"
                                className="input rounded-[5px] input-bordered text-sm c-font1"
                                value={positiveMark}
                                onChange={(e) => setPositiveMark(parseInt(e.target.value))}
                            />
                        </div>
                        <div className="form-control w-full">
                            <label className="label">
                                <span className="label-text">Negative Mark</span>
                            </label>
                            <input
                                type="number"
                                placeholder="Negative Mark"
                                className="input rounded-[5px] input-bordered text-sm c-font1"
                                value={negativeMark}
                                onChange={(e) => setNegativeMark(parseInt(e.target.value))}
                            />
                        </div>
                        <div className="form-control w-full">
                            <label className="label">
                                <span className="label-text">Sorting Params</span>
                            </label>
                            <input
                                type="text"
                                placeholder="Sorting Params"
                                className="input rounded-[5px] input-bordered text-sm c-font1"
                                value={sortingParams}
                                onChange={(e) => setSortingParams(e.target.value)}
                            />
                        </div>
                        <div className="form-control w-full">
                            <label className="label">
                                <span className="label-text">Visibility</span>
                            </label>
                            <select
                                className="select w-full select-bordered c-font1 !rounded-[5px]"
                                value={visibility}
                                onChange={(event) => {
                                    setVisibility(event.target.value);
                                }}
                            >
                                <option value="1">Visible</option>
                                <option value="0">Not visible</option>
                            </select>
                        </div>
                        <button type="submit" className="btn w-full rounded-[5px] mt-4">
                            Submit
                        </button>
                    </form>

                </label>
            </label>

        </>
    )
}
export default Main;
