import React, {useEffect, useRef, useState} from "react";
import axios from "axios";
import {getUserData} from "../../utils/LocalHandler";
import {BsStopwatch} from "react-icons/bs";
import {SiPlanetscale} from "react-icons/si";
import Swal from "sweetalert2";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {RxCross2} from "react-icons/rx";
import LoadingTableComponent from "../loadingTable.component";
import {API_BASE_URL} from "../../utils/config";
import {App_Name} from "../../utils/name";
import toast, {Toaster} from "react-hot-toast";
const CarouselPage=(props)=>{
    const [isLoading, setIsLoading] = useState(true);

    const [searchQuery, setSearchQuery] = useState('');
    const [pendingPayments, setPendingPayments] = useState([]);

    const navigate=useNavigate()
    async function fetchPendingPayments() {
        const userDetails = getUserData();
        const token = userDetails.token;
        const configData = {
            token,
            crud_type: "read",
            db_table_name: "carousel",
            action_performing: "get-carousel",
        };
        const response = await axios({
            url:`${API_BASE_URL}/get-carousel`,
            method: "post",
            data: configData
        });
        let data1 = response['data'];
        setPendingPayments(data1.data);
        setIsLoading(false)
        // console.log(data1.data)
        if (data1.status == 500 || data1.status == 401) return navigate("/login")
    }

    useEffect(() => {
        fetchPendingPayments();
    }, []);

    // console.log(pendingPayments)
    const filteredItems = pendingPayments.filter((payment) =>
        payment.carousel_title.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const handleRemove= async (id)=> {
        Swal.fire({
            title: `Write <b>delete</b> to confirm delete`,
            text:'This is permanent delete',
            input: 'text',
            inputAttributes: {
                autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: 'Delete',
            showLoaderOnConfirm: true,
        }).then(async (result) => {
            if (result.isConfirmed) {
                // /add-carousel
                const userDetails = getUserData();
                const token = userDetails.token;

                let options = {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        token: token,
                        id: filteredItems[id].id,
                    }),
                };
                const response = await fetch(
                    `${API_BASE_URL}/delete-carousel`,
                    options
                );
                let resData = await response.json();
                console.log(resData);
                if (resData["status"] === 404) {
                }
            }
        })
    }


    const closeBtnRef=useRef();

    const [logoUrl,setLogoUrl]=useState();
    const [title,setTitle]=useState();
    const [action,setAction]=useState();
    const [actionStatement,setActionStatement]=useState();


    const handleAdd= async (e)=>{
        e.preventDefault();
        const userDetails = getUserData();
        const token = userDetails.token;
        let options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                token: token,
                carousel_title:title,
                carousel_url:logoUrl,
                action_type:action,
                action_statement:actionStatement
            }),
        };
        const response = await fetch(
            `${API_BASE_URL}/add-carousel`,
            options
        );
        let resData = await response.json();

        if (resData["status"] === 404) {
        }

        setTitle("")
        setActionStatement("")
        setLogoUrl("")
        setAction("")
        closeBtnRef.current.click();

    }


    const editRef=useRef();
    const [editModalId,setEditModalId]=useState()

    const [logo, setLogo] = useState();
    const [titleEdit, setTitleEdit] = useState();
    const [indexing, setIndexing] = useState();
    const [actionEdit, setActionEdit] = useState();
    const [actionStatementEdit, setActionStatementEdit] = useState();
    const [live, setLive] = useState();

    const clear = () => {
        setLogo("");
        setTitleEdit("");
        setIndexing("");
        setActionEdit("");
        setActionStatementEdit("");
        setLive("");
    }


    const editNew=async (e) => {
        e.preventDefault()
        editRef.current.click();

        const id=editModalId;
        const userDetails = getUserData();
        const token = userDetails.token;

       toast("Updating...")


        let options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                token: token,
                id: pendingPayments[id].id,
                action_type: actionEdit,
                indexing:indexing,
                action_statement: actionStatementEdit,
                carousel_title:titleEdit,
                carousel_url: logo,
                status: live,
            }),
        };
        const response = await fetch(
            `${API_BASE_URL}/update-carousel`,
            options
        );
        let resData = await response.json();
        // console.log(resData);
        if (resData["status"] === 200) {
            fetchPendingPayments();
            toast.success("Updated successfully");
        }
        clear()
    }
    const handleEditNew=(id)=>{
       setEditModalId(id)

        setLogo(pendingPayments[id].carousel_url)
        setTitleEdit(pendingPayments[id].carousel_title)
        setIndexing(pendingPayments[id].indexing)
        setActionEdit(pendingPayments[id].action_type);
        setActionStatementEdit(pendingPayments[id].action_statement)
        setLive(pendingPayments[id].status)
        editRef.current.click();

    }

    return(
        <>

            <div className="bg-base-100 !p-4 m-0 flex flex-col h-screen ">
                {/* Header */}
                <header >
                    <div className=" mx-auto">
                        <div className="grid  grid-cols-3 mb-2">
                            <div className="col-span-2">
                                <Link to="/" className="mr-1 max-sm:ml-0 max-sm:mt-1 capitalize btn-warning btn  !rounded-[2px]  max-sm:text-sm max-sm:btn-sm" > {App_Name}</Link>
                                <button className=" btn bg-gray-300  btn-ghost !rounded-[2px] font-bold max-sm:text-sm max-sm:btn-sm">Carousels {pendingPayments.length}</button>

                            </div>
                            <div className="justify-self-end">
                                <div className="form-control ">
                                    <div className="rounded  flex">
                                        {/*<label htmlFor="CarouselModal" className="btn btn-ghost m-1 text-3xl"><AiOutlinePlusCircle/></label>*/}
                                        <input
                                            className="input input-bordered rounded-[2px]  mr-1 max-sm:input-xs max-sm:w-28"
                                            type="text"
                                            placeholder="Search Carousel"
                                            value={searchQuery}
                                            onChange={(event) => setSearchQuery(event.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>

                {/* Middle container */}
                <main className="flex-1 overflow-y-auto overflow-auto scrollbar-thin  ">
                    <div className="h-[90%] overflow-x-scroll overflow-y-scroll">
                        {
                            !isLoading?<table className="border-separate w-full table-zebra table  table-compact ">
                                <thead className="border-b sticky top-0">
                                <tr>
                                    {/*<th className="bg-[#50DBB4] pl-2 pr-2 "></th>*/}
                                    <th className="bg-[#50DBB4] pl-6 pr-6 ">Logo</th>
                                    <th className="bg-[#50DBB4] pl-2 pr-2">Title</th>
                                    <th className="bg-[#50DBB4] pl-2 pr-2">indexing</th>
                                    <th className="bg-[#50DBB4] pl-20 z-20 pr-20">Action</th>
                                    <th className="bg-[#50DBB4] pl-10 pr-10">Action Statement</th>
                                    <th className="bg-[#50DBB4] pl-10 pr-10">Live</th>
                                    <th className="bg-[#50DBB4] pl-10 pr-10">Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                { filteredItems.map((e, id) => (
                                    <tr key={id} >
                                        {/*<th>{id + 1}</th>*/}
                                        <th >
                                            {
                                                <img className="h-[28px] w-[50px]  rounded-[5px]" src={e.carousel_url}   />
                                            }
                                        </th>
                                        <td >
                                            {
                                                e.carousel_title
                                            }
                                        </td>
                                        <td >
                                            {
                                                e.indexing
                                            }
                                        </td>
                                        <td >
                                            {
                                                <select className="select select-xs select-bordered w-full max-w-xs z-0" value={e.action_type} disabled>
                                                    <option value="target_exam">Target Exam</option>
                                                    <option value="launch_url">Launch Url</option>
                                                </select>
                                            }
                                        </td>
                                        <td >
                                            {
                                                e.action_type=="launch_url"?<a target="_blank" href={e.action_statement}><SiPlanetscale className="text-2xl text-[#242B2E]"/></a>:e.action_statement
                                            }
                                        </td>
                                        <td >
                                            {
                                                e.status==1?
                                                    <span
                                                        className="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight"
                                                    >
                                                    <span
                                                        aria-hidden
                                                        className="absolute inset-0 bg-green-200 opacity-50 rounded-full"
                                                    ></span>
                                                    <span className="relative">Live</span>
                                                </span>:<button className="btn m-1 btn-sm text-xl btn-ghost"><BsStopwatch/></button>
                                            }

                                        </td>
                                        <td >
                                            <div className="btn-group">
                                                {
                                                    <button
                                                        className="btn btn-sm  btn-outline !rounded-[2px]"
                                                        onClick={() => handleEditNew(id)}
                                                    >
                                                        {/*<AiFillEdit/>*/} Edit
                                                    </button>
                                                }
                                            </div>
                                        </td>
                                    </tr>
                                )) }
                                </tbody>
                            </table>:<LoadingTableComponent/>
                        }
                    </div>
                </main>

            </div>



        {/*    modal*/}

            <input type="checkbox" id="CarouselModal" className="modal-toggle" />
            <label htmlFor="CarouselModal" className="modal cursor-pointer">
                <label className="modal-box pt-0 pb-0 relative" htmlFor="">
                    <div className="modal-header sticky top-0 ml-[-28px] mr-[-28px] p-2 bg-[#120e43] text-white">
                        <p className="text-2xl font-bold c-font1">Add Carousel Data</p>
                        <div className="card-actions  justify-end">
                            <label ref={closeBtnRef} htmlFor="CarouselModal" className="btn btn-ghost btn-sm mt-[-30px]">
                                <RxCross2/>
                            </label>
                        </div>
                    </div>

                    <form className="text-center" onSubmit={handleAdd}>
                        <div className="form-control ">
                            <label className="label">
                                <span className="label-text">Enter logo Url</span>
                            </label>
                            <input type="text" placeholder="Enter logo url" className="input rounded-[5px] input-bordered text-sm   !rounded-sm c-font1"
                                   value={logoUrl} onChange={(e)=>{setLogoUrl(e.target.value);}}/>
                        </div>
                        <div className="form-control ">
                            <label className="label">
                                <span className="label-text">Enter Title</span>
                            </label>
                            <input type="text" placeholder="Enter title" className="input rounded-[5px] input-bordered text-sm   !rounded-sm c-font1"
                                   value={title} onChange={(e)=>{setTitle(e.target.value);}}/>
                        </div>
                        <div className="form-control ">
                            <label className="label">
                                <span className="label-text">Select Action</span>
                            </label>
                            <select className="select select-bordered " value={action}
                                    onChange={(event) => {
                                        setAction(event.target.value)
                                    }}>
                                <option value="target_exam">Target Exam</option>
                                <option value="launch_url">Launch Url</option>
                            </select>
                        </div>
                        <div className="form-control ">
                            <label className="label">
                                <span className="label-text">Enter Action Statement</span>
                            </label>
                            <input type="text" placeholder="Enter Action Statement" className="input rounded-[5px] input-bordered text-sm  !rounded-sm c-font1"
                                   value={actionStatement} onChange={(e)=>{setActionStatement(e.target.value);}}/>
                        </div>
                        <div className="modal-footer sticky bottom-0 top-0 ml-[-28px] mr-[-28px]  ">
                            <button type="submit" className="mt-2 btn w-full bg-[#120e43] rounded-[5px]">Create</button>
                        </div>
                    </form>
                </label>
            </label>


            <input type="checkbox" id="editCarouselModal" className="modal-toggle" />
            <label htmlFor="editCarouselModal" className="modal cursor-pointer">
                <label className="modal-box pt-0 pb-0 relative" htmlFor="">
                    <div className="modal-header sticky top-0 ml-[-28px] mr-[-28px] p-2 bg-[#120e43] text-white">
                        <p className="text-2xl !pt-[-50px] font-bold c-font1 text-center">{titleEdit}</p>
                        <div className="card-actions  justify-end">
                            <label ref={editRef} htmlFor="editCarouselModal" className="btn btn-ghost btn-sm mt-[-30px]">
                                <RxCross2/>
                            </label>
                        </div>
                    </div>
                    <form onSubmit={editNew}>
                        <div className="form-control">
                            <label className="label">
                                <span className="label-text"> Background Image</span>
                            </label>
                            <input
                                type="text"
                                placeholder="Enter logo url"
                                className="input rounded-[5px] input-bordered text-sm !rounded-sm c-font1"
                                value={logo}
                                onChange={(e) => setLogo(e.target.value)}
                            />
                        </div>

                        <div className="form-control">
                            <label className="label">
                                <span className="label-text">Title</span>
                            </label>
                            <input
                                type="text"
                                placeholder="Enter title"
                                className="input rounded-[5px] input-bordered text-sm !rounded-sm c-font1"
                                value={titleEdit}
                                onChange={(e) => setTitleEdit(e.target.value)}
                            />
                        </div>

                        <div className="form-control">
                            <label className="label">
                                <span className="label-text">Indexing</span>
                            </label>
                            <input
                                type="text"
                                placeholder="Enter indexing"
                                className="input rounded-[5px] input-bordered text-sm !rounded-sm c-font1"
                                value={indexing}
                                onChange={(e) => setIndexing(e.target.value)}
                            />
                        </div>

                        <div className="form-control">
                            <label className="label">
                                <span className="label-text">Action</span>
                            </label>
                            <select className="select select-bordered "  value={actionEdit}
                                    onChange={(e) => setActionEdit(e.target.value)}>
                                <option value="target_exam">Target Exam</option>
                                <option value="launch_url">Launch Url</option>
                            </select>
                        </div>

                        <div className="form-control">
                            <label className="label">
                                <span className="label-text">Action Statement</span>
                            </label>
                            <input
                                type="text"
                                placeholder="Enter action statement"
                                className="input rounded-[5px] input-bordered text-sm !rounded-sm c-font1"
                                value={actionStatementEdit}
                                onChange={(e) => setActionStatementEdit(e.target.value)}
                            />
                        </div>

                        <div className="form-control">
                            <label className="label">
                                <span className="label-text">Live</span>
                            </label>
                            <select className="select w-full select-bordered c-font1 !rounded-[2px] " value={live}
                                    onChange={(event) => {
                                        setLive(event.target.value)

                                    }}>
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                            </select>
                        </div>
                        <div className="modal-footer sticky bottom-0 top-0 ml-[-28px] mr-[-28px]  ">
                            <button type="submit" className="mt-2 btn w-full bg-[#120e43] rounded-[5px]">Update</button>
                        </div>
                    </form>
                </label>
            </label>

            <Toaster/>
        </>
    )
}
export default CarouselPage;
