import React, { useEffect, useState } from "react";
import axios from "axios";
import ReactPaginate from "react-paginate";
import LoadingTableComponent from "../../loadingTable.component";
import { getUserData } from "../../../utils/LocalHandler";
import Swal from "sweetalert2";
import { API_BASE_URL } from "../../../utils/config";
import { Link, useNavigate } from "react-router-dom";
import { App_Name } from "../../../utils/name";
import {AiOutlineSearch} from "react-icons/ai";

const Payments = () => {
	const [isLoading, setIsLoading] = useState(true);
	const [searchQuery, setSearchQuery] = useState('');
	const [pendingPayments, setPendingPayments] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState();
	const [totalRecords, setTotalRecords] = useState();
	const navigate = useNavigate();

	const fetchData = async (page) => {
		try {
			setIsLoading(true)
			const userDetails = getUserData();
			const token = userDetails.token;
			const configData = { token, page };
			const response = await axios.post(`${API_BASE_URL}/get-payments?page=${page}`, configData);
			const { data, totalPages,totalRecords, currentPage } = response.data;
			setPendingPayments(data);
			setTotalPages(totalPages);
			// setCurrentPage(currentPage);
			setTotalRecords(totalRecords)
			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
			if (error.response && (error.response.status === 500 || error.response.status === 401)) {
				navigate("/login");
			}
		}
	};

	useEffect(() => {
		fetchData(currentPage);
	}, [currentPage]);



	return (
		<>
			<div className="bg-base-100 !p-4 !pt-0 m-0 flex flex-col h-screen">
				{/* Header */}
				<header className="flex items-center gap-2">
					<p className="text-xs badge badge-outline">Total Payment {totalRecords}</p>
					<p className="text-xs badge badge-outline">Page {currentPage}</p>
					<p className="text-xs badge badge-outline ">Total Payment/Page {pendingPayments.length}</p>
				</header>

				{/* Middle container */}
				<main className="flex-1 h-[90%] overflow-y-auto">
					<div >
						{!isLoading? (
							<table className="border-separate w-full table-zebra table table-auto table-compact">
								<thead className="border-b sticky top-0">
								<tr className="text-center">
									<th className="bg-[#50DBB4] pl-2 pr-2 !w-44">Username</th>
									<th className="bg-[#50DBB4] pl-2 pr-2">Phone</th>
									<th className="bg-[#50DBB4] pl-2 pr-2">Exam Name</th>
									<th className="bg-[#50DBB4] pl-2 pr-2">Real Amount</th>
									<th className="bg-[#50DBB4] pl-2 pr-2">Times</th>
									<th className="bg-[#50DBB4] pl-2 pr-2">Date</th>
									<th className="bg-[#50DBB4] pl-2 pr-2">Remarks</th>
								</tr>
								</thead>
								<tbody>
								{
									pendingPayments.map((e, id) => (
										<tr key={id} className="hover">
											<td className="!h-14 !w-44">{e.user_name}</td>
											<td>{e.phone}</td>
											<td>{e.exam_name}</td>
											<td>{e.real_amount}</td>
											<td>{e.times}</td>
											<td>{e.date}</td>
											<td>{e.remarks}</td>
										</tr>
									))}
								</tbody>
							</table>
						) : (
							<LoadingTableComponent />
						)}
					</div>
				</main>

				{/* Footer */}
				<footer>
					<div className="container mx-auto text-center">
						<div className="flex justify-center">
							<ReactPaginate
								previousLabel={'Previous'}
								nextLabel={'Next'}
								breakLabel={'...'}
								pageCount={totalPages}
								marginPagesDisplayed={1}
								pageRangeDisplayed={3}
								onPageChange={()=>(setCurrentPage(currentPage+1))}
								containerClassName="flex justify-center mt-4"
								pageClassName="mx-2"
								pageLinkClassName="border px-3 py-2 rounded focus:outline-none focus:border-blue-500 transition duration-150 ease-in-out"
								activeClassName="bg-blue-500 text-white"
							/>
						</div>
					</div>
				</footer>
			</div>
		</>
	);
};

export default Payments;
