import Swal from "sweetalert2";
import {useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import {MdEmail, MdPhone} from "react-icons/md";
import {RiLockPasswordFill} from "react-icons/ri";
import img1 from "../../assets/12146011_Wavy_Gen-01_Single-07.jpg"
import anim from "../../assets/78126-secure-login.json"
import Lottie from "lottie-react";
import { addDataWithKey } from "../../utils/LocalHandler";
import axoisApi from "../../api/axios";
import {App_Name} from "../../utils/name";
import toast, {Toaster} from "react-hot-toast";
const Login=()=>{

    const [isLoading, setIsLoading] = useState(false);
    const signInModal = useRef(null);
    const [phone_no,setPhone_no]=useState("");
    const [password,setPassword]=useState("");
    const [platform,setPlatform]=useState("Web")

    // const [teacher,setTeacher]=useState([]);

    let teacher=[]

    const navigate=useNavigate();

    async function handleSignIn(event){
        event.preventDefault()
        setIsLoading(true)

        const response = await axoisApi({
          url: "/authenticate-admin",
          method: "post",
          data: {
            phone:phone_no,
            password,
          },
        });

        const data= response['data'];
        // console.log(data[data].admin_name)
        if(data.status===200) {
            addDataWithKey(data["data"][0])
            localStorage.setItem('name',data)
            // Swal.fire({
            //     title:'Login Success!',
            //     text:'Welcome to the portal!',
            //     icon:'success'
            // }).then((result) => {
            //     if (result.isConfirmed) {
            //         .auth(true)
            //         // signInModal.current.click();
            //     }
            // })
            toast.success("Login success!")
            navigate("/")
        }
        else if(data.status==404){
            setIsLoading(false)
            Swal.fire({
                title:'Invalid credentials!',
                text:'please check the credentials !',
                icon:'error'
            }).then((result) => {
                if (result.isConfirmed) {
                }
            })
        }
        else{
            setIsLoading(false)
            Swal.fire({
                title:'Login Failed!',
                text:'please check the credentials !',
                icon:'error'
            }).then((result) => {
                if (result.isConfirmed) {
                    signInModal.current.click();
                }
            })
        }
        setPhone_no("")
        setPassword("")
    }


    return(
        <>
            <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
                <div className="sm:mx-auto mt-10 sm:w-full sm:max-w-sm">
                    <img
                        className="mx-auto h-10 w-auto"
                        src="https://anytestseries.in/logo.png"
                        alt="Your Company"
                    />
                    <h2 className="mt-10 text-center text-2xl font-normal leading-9 tracking-tight text-gray-900">
                        Login in to your admin panel
                    </h2>
                </div>

                <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                    <form className="space-y-6" onSubmit={handleSignIn}>
                        <div >
                            <div>
                                <label htmlFor="phone" className="block text-sm font-medium leading-6 text-gray-900">
                                    Phone Number
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="phone"
                                        name="phone"
                                        type="phone"
                                        value={phone_no} onChange={(e)=>(setPhone_no(e.target.value))} required
                                        className="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-yellow-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                            <div>
                                <label htmlFor="phone" className="block text-sm font-medium leading-6 text-gray-900">
                                    Password
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="password"
                                        name="password"
                                        type="password"
                                        value={password} onChange={(e)=>(setPassword(e.target.value))} required
                                        className="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-yellow-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                            <div className="mt-2">
                                {
                                    !isLoading?
                                        <button
                                            type="submit"
                                            className="flex w-full justify-center rounded-md bg-yellow-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-yellow-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-600"
                                        >
                                            Log in
                                        </button>:<button
                                            type="submit"
                                            disabled
                                            className="flex w-full loading justify-center rounded-md bg-yellow-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-yellow-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-600"
                                        >Logging.....
                                        </button>
                                }

                            </div>

                        </div>
                    </form>
                    <p className="mt-10 text-center text-sm text-gray-500">
                       Facing an issue?{' '}
                        <a href="#" className="font-semibold leading-6 text-yellow-600 hover:text-yellow-500">
                            Contact us
                        </a>
                    </p>
                </div>
            </div>

<Toaster/>
        </>
    )
}
export default Login;
