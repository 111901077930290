import React, {useRef, useState} from "react";
import Payments from "./Payments";
import PendingPaymentPage from "../pendingPayment.page";
import {Link} from "react-router-dom";
import {App_Name} from "../../../utils/name";
import {AiOutlineSearch} from "react-icons/ai";
import {getUserData} from "../../../utils/LocalHandler";
import axios from "axios";
import {API_BASE_URL} from "../../../utils/config";
import {RxCross2} from "react-icons/rx";
import LoadingTableComponent from "../../loadingTable.component";
import Swal from "sweetalert2";

const IndexPayment = () => {
	const [tab,setTab]=useState(1)
	const searchRef=useRef()
	const [isLoading1, setIsLoading1] = useState(true);
	const [searchQuery, setSearchQuery] = useState('');
	const [searchData, setSearchData] = useState([]);

	const searchByPhone = async (page) => {
		try {
			setIsLoading1(true)
			const userDetails = getUserData();
			const token = userDetails.token;
			const configData = { token, phone:searchQuery };
			const response = await axios.post(`${API_BASE_URL}/search-payment-by-phone`, configData);
			const { data } = response.data;
			if(data.length>0){
				setSearchData(data)
				searchRef.current.click()
			}
			setIsLoading1(false)
		} catch (error) {
			setIsLoading1(false);
			if (error.response && (error.response.status === 500 || error.response.status === 401)) {
				// navigate("/login");
			}
		}
	};

	const handleActivate = async (id) => {
		try {
			const userDetails = getUserData();
			const token = userDetails.token;
			const configData = { token, payment_id: id };
			const response = await axios.post(`${API_BASE_URL}/activate-exam-from-pending-payment`, configData);
			const { data } = response.data;
			if (data.status === 200) {
				Swal.fire({
					icon: 'success',
					title: 'Exam activated ...',
					text: 'Exam activated successfully',
				});
			}
		} catch (error) {
			if (error.response && (error.response.status === 500 || error.response.status === 401)) {
				// navigate("/login");
			}
		}
	};

 return(
	 <>

		 <header>
			 <div className="mx-auto p-2 pb-1">
				 <div className="grid grid-cols-3 ">
					 <div className="col-span-2 flex items-center">
						 <Link to="/" className="mr-1 max-sm:ml-0 max-sm:mt-1 capitalize btn-warning btn !rounded-[2px]  max-sm:text-sm max-sm:btn-sm"> {App_Name}</Link>
						 <div className="tabs  p-2 gap-2   border max-sm:mt-1  tabs-boxed">
							 <a className={`tab bg-white max-sm:tab-sm tab-bordered ${tab==1?"tab-active !bg-neutral":null}`}  onClick={()=>(
								 setTab(1))}>Paid</a>
							 <a className={`tab bg-white max-sm:tab-sm tab-bordered ${tab==2?"tab-active !bg-neutral":null}`}  onClick={()=>(
								 setTab(2))}>Pending</a>
						 </div>
					 </div>
					 <div className="justify-self-end">
						 <div className="form-control">
							 <div className="border rounded overflow-hidden flex items-center mt-2">
								 <input
									 className="input input-sm input-bordered rounded-[2px] max-sm:input-sm max-sm:w-28"
									 type="search"
									 placeholder="Search Payment by Phone"
									 value={searchQuery}
									 onChange={(event) => {
										 setSearchQuery(event.target.value);
									 }}
								 />
								 <button className="btn btn-sm btn-outline rounded-[0px]" onClick={searchByPhone}><AiOutlineSearch/></button>
							 </div>
						 </div>
					 </div>
				 </div>
			 </div>
		 </header>




		 {
			 tab==1?
				 <Payments/>
				 :tab==2?<PendingPaymentPage/>:null
		 }


		 <input type="checkbox" id="searchModal" className="modal-toggle"/>
		 <label htmlFor="searchModal" className="modal 	 cursor-pointer">
			 <label className="modal-box modal-top pb-0 pt-0 relative !w-11/12 max-w-5xl" htmlFor="">
				 <div className="modal-header sticky top-0 ml-[-28px] mr-[-28px] p-2 ">
					 <p className="!text-md font-bold mt-auto mb-auto c-font1 text-center">Search by Phone</p>
					 <div className="card-actions  justify-end">
						 <label ref={searchRef} htmlFor="searchModal" className="btn btn-ghost bg-transparent btn-sm mt-[-25px]">
							 <RxCross2/>
						 </label>
					 </div>
				 </div>
				 <main className="flex-1 h-[90%] overflow-y-auto">
					 <div className=" overflow-x-scroll overflow-y-scroll">
						 {!isLoading1? (
							 <table className="border-separate w-full table-zebra table table-auto table-compact">
								 <thead className="border-b sticky top-0">
								 <tr className="text-center">
									 <th className="bg-[#50DBB4] pl-2 pr-2 !w-44">Username</th>
									 <th className="bg-[#50DBB4] pl-2 pr-2">Phone</th>
									 <th className="bg-[#50DBB4] pl-2 pr-2">Exam Name</th>
									 <th className="bg-[#50DBB4] pl-2 pr-2">Real Amount</th>
									 <th className="bg-[#50DBB4] pl-2 pr-2">Times</th>
									 <th className="bg-[#50DBB4] pl-2 pr-2">Date</th>
									 <th className="bg-[#50DBB4] pl-2 pr-2">Remarks</th>
									 <th className="bg-[#50DBB4] pl-10 pr-10">Action</th>
								 </tr>
								 </thead>
								 <tbody>
								 {
									 searchData.map((e, id) => (
										 <tr key={id} className="hover">
											 <td className="!h-14 !w-44">{e.user_name}</td>
											 <td>{e.phone}</td>
											 <td>{e.exam_name}</td>
											 <td>{e.real_amount}</td>
											 <td>{e.times}</td>
											 <td>{e.date}</td>
											 <td>{e.remarks}</td>
											 <td>
												 {
													 e.status==1?
														 null:
														 <button className="btn btn-sm !rounded-[2px] btn-outline " onClick={() => handleActivate(e.id)}>
															 Activate
														 </button>
												 }
											 </td>
										 </tr>
									 ))}
								 </tbody>
							 </table>
						 ) : (
							 <LoadingTableComponent />
						 )}
					 </div>
				 </main>
			 </label>
		 </label>
	 </>
 )
}
export default IndexPayment;
