import React, { useEffect, useRef } from 'react';
import Plyr from 'plyr';
import 'plyr/dist/plyr.css'; // Import Plyr CSS
import Hls from 'hls.js';

const VideoPlayer = ({ videoUrl }) => {
    const videoRef = useRef(null);

    const getQualityOptions = async (videoUrl) => {
        if (!Hls.isSupported()) {
            return [];
        }

        const hls = new Hls();
        hls.loadSource(videoUrl);
        await hls.attachMedia(videoRef.current).catch((error) => {
            console.error('Error attaching media:', error);
        });

        // Wait for the manifest to be parsed and get available quality levels
        await new Promise((resolve) => {
            hls.on(Hls.Events.MANIFEST_PARSED, () => {
                resolve();
            });
        });

        const qualityOptions = hls.levels.map((level) => level.height);
        hls.destroy();

        return qualityOptions;
    };

    useEffect(() => {
        const initializePlayer = async () => {
            if (!videoUrl) {
                return;
            }

            // Create Plyr instance with options
            const player = new Plyr(videoRef.current, {
                captions: { active: true, update: true, language: 'en' },
            });

            if (Hls.isSupported()) {
                const qualityOptions = await getQualityOptions(videoUrl);

                if (qualityOptions.length > 0) {
                    qualityOptions.forEach((quality) => {
                        player.quality.addQuality(quality, quality + 'p');
                    });
                }

                const hls = new Hls();
                hls.loadSource(videoUrl);
                hls.attachMedia(videoRef.current);
                window.hls = hls;

                // Handle changing captions
                player.on('languagechange', () => {
                    setTimeout(() => (hls.subtitleTrack = player.currentTrack), 50);
                });
            } else {
                videoRef.current.src = videoUrl;
            }

            // Expose player so it can be used from the console
            window.player = player;
        };

        initializePlayer();

        // Clean up Plyr instance and Hls.js on unmount
        return () => {
            if (window.player) {
                window.player.destroy();
            }
            if (window.hls) {
                window.hls.destroy();
            }
        };
    }, [videoUrl]);

    return (
        <div>
            <video ref={videoRef}></video>
        </div>
    );
};

export default VideoPlayer;
