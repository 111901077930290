import React, {useEffect, useRef, useState} from "react";
import axios from "axios";
import {AiFillCheckCircle, AiFillDelete, AiFillEdit, AiFillPlusCircle, AiOutlinePlusCircle} from "react-icons/ai";
import DatePicker from "react-datepicker";
import {getUserData} from "../../utils/LocalHandler";
import OmrModal from "../omr/omr.modal";
import LoadingComponent from "../Loading.component";
import DeleteOmrModal from "../omr/delete.omrModal";
import Swal from "sweetalert2";
import {RxCross2} from "react-icons/rx";
import {BsFillBellFill} from "react-icons/bs";
import {RiPagesLine} from "react-icons/ri";
import LoadingTableComponent from "../loadingTable.component";
import {API_BASE_URL} from "../../utils/config";
import {MdCancel} from "react-icons/md";
import {json, Link, useNavigate} from "react-router-dom";
import {App_Name} from "../../utils/name";
import {formatDate} from "../../utils/formatDate";
import {getRole} from "../../utils/getRole";
import toast, {Toaster} from "react-hot-toast";

const OmrTest=()=>{

    const role=getRole()

    const [isLoading, setIsLoading] = useState(true);

    const [searchQuery, setSearchQuery] = useState('');

    const [omrData, setOmrData] = useState([]);


    const [selectedValues, setSelectedValues] = useState([]); // om sheet array

    async function fetchOmrTests() {
        const userDetails = getUserData();
        const token = userDetails.token;
        const configData = {
            token,
        };
        const response = await axios({
            url: `${API_BASE_URL}/read-omr-list`,
            method: "post",
            data: configData
        });
        let data1 = response['data'];
        setOmrData(data1.data);
        // console.log(data1.data)
        setIsLoading(false)
        if (data1.status == 500 || data1.status == 401) return alert("logout and login")
    }

    async function fetchAnswers(set_id) {
        const userDetails = getUserData();
        const token = userDetails.token;
        const configData = {
            token,
            set_id
        };
        const response = await axios({
            url: `${API_BASE_URL}/get-answer-by-set`,
            method: "post",
            data: configData
        });
        let data1 = response['data'];

        if(data1.data.length>0)   setSelectedValues(data1.data);

    }

    // console.log(pendingPayments)
    const filteredItems = omrData.filter((payment) =>
        payment.exam_name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const closeBtnRef=useRef();
    const closeBtnRef2=useRef();
    const editRef=useRef();

    const [omr,setOmr]=useState();

    function handleRemove(id) {
        Swal.fire({
            title: `Write <b>reset</b> to confirm reset`,
            // text:'This is permanent reset',
            input: 'text',
            inputAttributes: {
                autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: 'Reset',
            showLoaderOnConfirm: true,
        }).then(async (result) => {
            if (result.isConfirmed) {
                const userDetails = getUserData();
                const token = userDetails.token;
                let options = {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        token: token,
                        id:filteredItems[id].id,
                        exam_id: filteredItems[id].exam_id,
                    }),
                };
                const response = await fetch(
                    `${API_BASE_URL}/reset-omr-exam`,
                    options
                );
                let resData = await response.json();
                console.log(resData);
                if (resData["status"] === 200) {
                    setOmrData(resData['data'])
                    toast.success( "Reset successfully");
                }
            }
        })
    }

    const handleAdd= async (e)=>{
        e.preventDefault();
        closeBtnRef.current.click();
        const userDetails = getUserData();
        const token = userDetails.token;
        let options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                token: token,
                exam_name:omr
            }),
        };
        const response = await fetch(
            `${API_BASE_URL}/add-omrTest`,
            options
        );
        let resData = await response.json();
        // console.log(resData);
        if (resData["status"] === 200) {
            toast.success("Created successfully");
                setOmrData(resData['data'])
        }



    }

    useEffect(() => {
        fetchOmrTests();
    }, []);


    const navigate=useNavigate();

    const [editIdOmr,setEditIdOmr]=useState('')
    const [examName, setExamName] = useState(""); // Exam name
    const [tq, setTq] = useState(""); // Total questions
    const [time, setTime] = useState(""); // Exam duration
    const [status, setStatus] = useState(""); // Exam status
    const [resultTime, setResultTime] = useState(""); // Result announcement time
    const [password, setPassword] = useState(""); // Exam password
    const [pm, setPm] = useState(""); // Exam password
    const [nm, setNm] = useState(""); // Exam password
    const [answerTable, setAnswerTable] = useState("");
    const [optionsCount, setOptionsCount] = useState();
    const [questionWithAnswer, setQuestionWithAnswer] = useState([]);

    function clearFields() {
        setExamName("");
        setTq("");
        setTime("");
        setStatus("");
        setResultTime("");
        setPassword("");
        setPm("")
        setNm("")
    }

    const updateNew=async (e) => {
        debugger
        e.preventDefault()
        const id=editIdOmr
        const userDetails = getUserData();
        const token = userDetails.token;
        editRef.current.click()

        const qWithA=JSON.parse(questionWithAnswer).slice(0,tq)


        let options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                token: token,
                id: filteredItems[id].id,
                positive_mark: pm,
                negative_mark: nm,
                exam_name: examName,
                answer_table: answerTable,
                options_count: optionsCount,
                total_questions: tq,
                time: time,
                examStartStatus: status,
                result_declaration_time: resultTime,
                password: password,
                question_with_answer:JSON.stringify(qWithA)
            }),
        };
        const response = await fetch(
            `${API_BASE_URL}/update-omr-exam`,
            options
        );
        let resData = await response.json();
        console.log(resData);
        if (resData["status"] === 200) {
            fetchOmrTests();
            toast.success("Updated successfully");
            clearFields();
        }
        clearFields();
    }
    const handleUpdateNew=(id)=>{
        setEditIdOmr(id)
        setExamName(filteredItems[id].exam_name)
        setAnswerTable(filteredItems[id].answer_table)
        setOptionsCount(filteredItems[id].options_count)
        setQuestionWithAnswer(filteredItems[id].question_with_answer)
        setTq(filteredItems[id].total_questions)
        setTime(filteredItems[id].time)
        setStatus(filteredItems[id].examStartStatus)
        setResultTime(filteredItems[id].result_declaration_time)
        setPassword(filteredItems[id].password)
        setNm(filteredItems[id].negative_mark)
        setPm(filteredItems[id].positive_mark)
        editRef.current.click()
    }


     async function handleActivate(id) {
        const userDetails = getUserData();
        const token = userDetails.token;
        const configData = {
            token,
            exam_id: filteredItems[id].exam_id
        };
        const response = await axios({
            url: `${API_BASE_URL}/activate-exam404`,
            method: "post",
            data: configData
        });
        let data1 = response['data'];

        if (data1.status == 200) {
            Swal.fire({
                icon: 'success',
                title: 'Exam activated ...',
                text: 'Exam activated successfully',
            })
            fetchOmrTests();
        }
        setIsLoading(false)
        if (data1.status == 500 || data1.status == 401) return navigate("/login")
    }

    const [sheetName, setSheetName] = useState();
    const [sheetId, setSheetId] = useState();
    const [sheetCount,setSheetCount]=useState()  // set id
    const handleOmrSheet = (id) => {

        let selectedValues1;
        // setSheetCount(filteredItems[id].total_questions)
        // console.log(filteredItems[id])
        if (filteredItems[id].question_with_answer) {
            selectedValues1 = JSON.parse(filteredItems[id].question_with_answer);

            // If there are more elements than total_questions, truncate the array
            // if (selectedValues1.length > filteredItems[id].total_questions) {
            //     selectedValues1 = selectedValues1.slice(0, filteredItems[id].total_questions);
            // }

            const missingQuestions = filteredItems[id].total_questions - selectedValues1.length;

            // If there are missing questions, add them with null values
            if (missingQuestions > 0) {
                for (let i = 1; i <= missingQuestions; i++) {
                    selectedValues1.push({
                        question_no: selectedValues1.length + i,
                        answer: null,
                    });
                }
            }
        } else if (filteredItems[id].question_with_answer === null) {
            selectedValues1 = [];
            for (let i = 1; i <= filteredItems[id].total_questions; i++) {
                selectedValues1.push({
                    question_no: i,
                    answer: null,
                });
            }
        }
        setSheetName(filteredItems[id].exam_name);
        setSelectedValues(selectedValues1);
        setSheetId(filteredItems[id].exam_id);
        closeBtnRef2.current.click();
    };

    const handleRadioChange = (e, key) => {
        const value = e.target.value;
        const updatedSelectedValues = [...selectedValues];
        updatedSelectedValues[key].answer = value;
        setSelectedValues(updatedSelectedValues);
    };

    //  omr sheet
    const handleSubmit = async (e) => {
        e.preventDefault();

        const hasNullAnswer = selectedValues.some((item) => item.answer === null);

        if (hasNullAnswer) {
          return toast.warn('Check your answer', {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
          }); // stop
        }
        else{
            const userDetails = getUserData();
            const token = userDetails.token;
            let options = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    token: token,
                    id: sheetId,
                    exam_name: sheetName,
                    question_answer: selectedValues
                })
            };
            const response = await fetch(
                `${API_BASE_URL}/update-omr-exam-question-answer`,
                options
            );
            let resData = await response.json();
            console.log(resData);

            if (resData.status === 200) {
                toast.success("Updated successfully");
                fetchOmrTests();
            }else if(resData.status === 201){
                toast.success("Nothing Updated");
            }

            closeBtnRef2.current.click();
        }
    };

    let indexTable=0;
    return(
        <>
            <div className="bg-base-100 !p-4 m-0 flex flex-col h-screen ">
                {/* Header */}
                <header >
                    <div className=" mx-auto">
                        <div className="grid  grid-cols-3  mb-2">
                            <div className="col-span-2">
                                <Link to="/" className="mr-1 max-sm:ml-0 max-sm:mt-1 capitalize btn-warning btn  !rounded-[2px]  max-sm:text-sm max-sm:btn-sm" > {App_Name}</Link>
                                <button className=" btn bg-gray-300  btn-ghost !rounded-[2px] font-bold max-sm:text-sm max-sm:btn-sm">All OMR Test {omrData.length} </button>
                            </div>
                            <div className="justify-self-end">
                                <div className="form-control ">
                                    <div className=" rounded  flex">
                                        <input
                                            className="input input-bordered rounded-[2px]  mr-1 max-sm:input-sm max-sm:w-28"
                                            type="text"
                                            placeholder="Search OMR Test"
                                            value={searchQuery}
                                            onChange={(event) => setSearchQuery(event.target.value)}
                                        />
                                        {/*<label htmlFor="omrModal" className="btn btn-ghost ml-1  bg-gray-300  !rounded-[2px] text-3xl max-sm:btn-sm"><AiOutlinePlusCircle/></label>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>

                {/* Middle container */}
                <main className="flex-1 overflow-y-auto ">
                    <div className="h-[90%] overflow-x-scroll overflow-y-scroll">
                        {
                            !isLoading? <table className="border-separate w-full table-zebra  table table-auto table-compact ">
                                <thead className="border-b sticky top-0">
                                <tr className="text-center">
                                    <th className="bg-[#50DBB4]">・</th>
                                    <th className="bg-[#50DBB4] ">Exam Name</th>
                                    {/*<th className="bg-[#50DBB4] pl-10 pr-10">Answer Table</th>*/}
                                    <th className="bg-[#50DBB4] pl-10 pr-10">TQ</th>
                                    <th className="bg-[#50DBB4] pl-10 pr-10">Time</th>
                                    <th className="bg-[#50DBB4] pl-10 pr-10">Status</th>
                                    <th className="bg-[#50DBB4] pl-10 pr-10">Result Time</th>
                                    <th className="bg-[#50DBB4] ">P.M.</th>
                                    <th className="bg-[#50DBB4] ">N.M.</th>
                                    <th className="bg-[#50DBB4] ">Password</th>
                                    <th className="bg-[#50DBB4] pl-20 pr-20">Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                { filteredItems.map((e, id) => {
                                    if(e.id==1 && e.exam_id==1){
                                        return null
                                    }else{
                                        indexTable+=1
                                   return( <tr key={id} className={"hover"}>
                                        <th>{indexTable }</th>
                                        <td>
                                            {
                                                e.exam_name.length<50?e.exam_name:e.exam_name.substring(0, 20)+"..."
                                            }
                                        </td>
                                        {/*<td>*/}
                                        {/*    {*/}
                                        {/*        e.answer_table*/}
                                        {/*    }*/}
                                        {/*</td>*/}
                                        <td>
                                            {
                                                e.total_questions
                                            } Questions
                                        </td>
                                        <td>
                                            {
                                                e.time
                                            } Min.
                                        </td>
                                        <td>
                                            {
                                                e.examStartStatus == 0 ? <p>Upcoming</p> : e.examStartStatus == 1 ?
                                                    <p>Running</p> : e.examStartStatus == 2 ?
                                                        <p>Entry Blocked</p> : e.examStartStatus == 3 ?
                                                            <p>Result Declared</p> : null
                                            }
                                        </td>
                                        <td>
                                            {
                                                e.result_declaration_time
                                            }
                                        </td>
                                        <td>
                                            {
                                                e.positive_mark
                                            }
                                        </td>
                                        <td>
                                            {
                                                e.negative_mark
                                            }
                                        </td>
                                        <td>
                                            {
                                                e.password
                                            }
                                        </td>
                                        <td>
                                            <div className="btn-group">
                                                {
                                                    <button
                                                        className="btn btn-sm btn-outline  !rounded-[2px]"
                                                        onClick={() => handleUpdateNew(id)}
                                                    > Edit
                                                    </button>
                                                }
                                                <button onClick={() => handleOmrSheet(id)}
                                                        className="btn btn-sm btn-outline ml-2  !rounded-[2px]">Answer
                                                </button>
                                                {
                                                    role === 1 ? null : role === 2 ?
                                                        <button onClick={() => handleRemove(id)}
                                                                className="btn ml-1  btn-error btn-sm !rounded-[2px]">
                                                            {/*<AiFillDelete/>*/} Reset
                                                        </button> : role === 3 ?
                                                            <button onClick={() => handleRemove(id)}
                                                                    className="btn ml-1  btn-error btn-sm !rounded-[2px]">
                                                                {/*<AiFillDelete/>*/} Reset
                                                            </button> : null
                                                }
                                                {/*<button  onClick={() => handleActivate(id)} htmlFor="DeleteOmrModal" className="btn btn-sm btn-outline ml-2  !rounded-[2px]">Activate</button>*/}
                                            </div>
                                        </td>
                                    </tr>
                                   )}})}
                                </tbody>
                            </table>:<LoadingTableComponent/>
                        }
                    </div>
                </main>

            </div>


            {/*<OmrModal/>*/}
            {/*<DeleteOmrModal/>*/}

            {/*    modal*/}

            <input type="checkbox" id="omrModal" className="modal-toggle" />
            <label htmlFor="omrModal" className="modal cursor-pointer">
                <label className="modal-box relative pb-0 pt-0" htmlFor="">
                    <div className="modal-header text-center sticky top-0 ml-[-28px] mr-[-28px] p-2 bg-[#120e43] text-white">
                        <p className="text-2xl font-bold c-font1">Create OMR Test</p>
                        <div className="card-actions  justify-end">
                            <label ref={closeBtnRef} htmlFor="omrModal" className="btn btn-ghost btn-sm mt-[-30px]">
                                <RxCross2/>
                            </label>
                        </div>
                    </div>

                    <form className="text-center" onSubmit={handleAdd}>
                        <div className="form-control">
                            <label className="label">
                                <span className="label-text">Exam Name</span>
                            </label>

                            <input type="text" placeholder="Enter data" className="input rounded-[2px]  input-bordered text-sm  w-full mb-2  !rounded-sm c-font1"
                                   value={omr} onChange={(e)=>{setOmr(e.target.value);}}/>
                        </div>
                        <div className="modal-footer sticky bottom-0 top-0 ml-[-28px] mr-[-28px]  ">
                            <button type="submit" className="mt-2 btn w-full bg-[#120e43] rounded-[5px]">Create</button>
                        </div>
                    </form>
                </label>
            </label>


            <input type="checkbox" id="editModal" className="modal-toggle" />
            <label htmlFor="editModal" className="modal cursor-pointer">
                <label className="modal-box relative pb-0 pt-0" htmlFor="">
                    <div className="modal-header text-center sticky top-0 ml-[-28px] mr-[-28px] p-2 bg-[#120e43] text-white">
                        <p className="text-2xl font-bold c-font1">{examName}</p>
                        <div className="card-actions  justify-end">
                            <label ref={editRef} htmlFor="editModal" className="btn btn-ghost btn-sm mt-[-30px]">
                                <RxCross2/>
                            </label>
                        </div>
                    </div>

                    <form onSubmit={updateNew}>
                        <div className="form-control">
                            <label className="label">
                                <span className="label-text">Exam Name</span>
                            </label>
                            <input
                                type="text"
                                className="input rounded-[5px] input-bordered  !rounded-sm c-font1"
                                value={examName}
                                onChange={(e) => {
                                    setExamName(e.target.value);
                                }}
                            />
                        </div>
                        <div className="form-control">
                            <label className="label">
                                <span className="label-text">Total Questions</span>
                            </label>
                            <input
                                type="number"
                                className="input rounded-[5px] input-bordered  !rounded-sm c-font1"
                                value={tq}
                                onChange={(e) => {
                                    setTq(e.target.value);
                                }}
                            />
                        </div>
                        <div className="form-control">
                            <label className="label">
                                <span className="label-text">Exam Duration</span>
                            </label>
                            <input
                                type="text"
                                className="input rounded-[5px] input-bordered  !rounded-sm c-font1"
                                value={time}
                                onChange={(e) => {
                                    setTime(e.target.value);
                                }}
                            />
                        </div>
                        <div className="form-control">
                            <label className="label">
                                <span className="label-text">Exam Status</span>
                            </label>
                            <select className="select w-full select-bordered c-font1 !rounded-[2px] "  value={status}
                                    onChange={(e) => {
                                        setStatus(e.target.value);
                                    }}>
                                <option value="0">Upcoming</option>
                                <option value="1">Running</option>
                                <option value="2">Entry Block</option>
                                <option value="3">Result Declared</option>
                            </select>
                        </div>
                        <div className="form-control">
                            <label className="label">
                                <span className="label-text">Result Announcement Time</span>
                            </label>
                            <DatePicker
                                showTimeSelect
                                setDefaultSelected={true}
                                dateFormat="yyyy-MM-dd HH:mm:ss"
                                timeFormat="HH:mm:ss"
                                placeholderText={resultTime}
                                selected={resultTime.length>0?new Date(resultTime.toString()):new Date()}
                                closeOnSelect={false}
                                shouldCloseOnSelect={false}
                                onChange={(date) => {
                                    setResultTime(formatDate(date))
                                }}
                                className="input input-bordered c-font1 z-20  w-full"
                            />

                        </div>
                        <div className="form-control">
                            <label className="label">
                                <span className="label-text">Positive Mark</span>
                            </label>
                            <input
                                type="text"
                                className="input rounded-[5px] input-bordered  !rounded-sm c-font1"
                                value={pm}
                                onChange={(e) => {
                                    setPm(e.target.value);
                                }}
                            />
                        </div>
                        <div className="form-control">
                            <label className="label">
                                <span className="label-text">Negative Mark</span>
                            </label>
                            <input
                                type="text"
                                className="input rounded-[5px] input-bordered  !rounded-sm c-font1"
                                value={nm}
                                onChange={(e) => {
                                    setNm(e.target.value);
                                }}
                            />
                        </div>
                        <div className="form-control">
                            <label className="label">
                                <span className="label-text">Answer Table</span>
                            </label>
                            <input
                                type="text"
                                className="input rounded-[5px] input-bordered  !rounded-sm c-font1"
                                value={answerTable}
                                onChange={(e) => {
                                    setAnswerTable(e.target.value);
                                }}
                            />
                        </div>
                        <div className="form-control">
                            <label className="label">
                                <span className="label-text">Option Count</span>
                            </label>
                            <select className="select w-full select-bordered c-font1 !rounded-[2px] "  value={optionsCount}
                                    onChange={(e) => {
                                        setOptionsCount(e.target.value);
                                    }}>
                                <option value="4">4</option>
                                <option value="5">5</option>
                            </select>
                        </div>
                        <div className="form-control">
                            <label className="label">
                                <span className="label-text">Exam Password</span>
                            </label>
                            <input
                                type="text"
                                className="input rounded-[5px] input-bordered  !rounded-sm c-font1"
                                value={password}
                                onChange={(e) => {
                                    setPassword(e.target.value);
                                }}
                            />
                        </div>

                        <div className="modal-footer sticky bottom-0 top-0 ml-[-28px] mr-[-28px]  ">
                            <button type="submit" className="mt-2 btn w-full bg-[#120e43] rounded-[5px]">Update</button>
                        </div>
                    </form>
                </label>
            </label>

            <input type="checkbox" id="setOmrSheet" className="modal-toggle" />
            <label htmlFor="setOmrSheet" className="modal cursor-pointer">
                <label className="modal-box   relative pb-0 pt-0" htmlFor="">
                    <div className="modal-header text-center sticky top-0 ml-[-28px] mr-[-28px] p-2 bg-[#120e43] text-white">
                        <p className="text-2xl font-bold c-font1">{sheetName}</p>


                        <div className="card-actions  justify-end">
                            <label ref={closeBtnRef2} htmlFor="setOmrSheet" className="btn btn-ghost btn-sm mt-[-30px]">
                                <RxCross2/>
                            </label>
                        </div>

                        <div className="form-control justify-end">
                            <label className="input-group  !rounded-[2px]">
                                <input type="text" placeholder="Enter Set Id" className="input !text-black input-bordered input-xs !rounded-[2px]"
                                       value={sheetCount} onChange={(e)=>(setSheetCount(e.target.value))}
                                />
                                <span className="btn btn-info btn-xs !rounded-[2px] capitalize" onClick={()=>(fetchAnswers(sheetCount))}>Get Answers</span>
                            </label>
                        </div>
                    </div>

                    <form className="text-center"  >
                        <div className=" h-96 !overflow-y-auto ">
                            {selectedValues.map((item, key) => (
                                <div className="join mt-auto mb-auto flex pt-1 pb-1 w-full" key={key}>
                                    <button className="btn mt-auto mb-auto btn-sm join-item btn-circle mr-4">
                                        {key + 1}
                                    </button>
                                    <div className="join flex w-full">
                                        <div className="form-control w-full">
                                            <label className="label cursor-pointer" style={{ justifyContent: "flex-end" }}>
                                                <input
                                                    type="radio"
                                                    name={`question-${key}`}
                                                    value="A"
                                                    className="radio checked:bg-blue-500"
                                                    checked={selectedValues[key] && selectedValues[key].answer === 'A'}
                                                    onChange={(e) => handleRadioChange(e, key)}
                                                    required
                                                />
                                                <span className="label-text m-2">A</span>
                                            </label>
                                        </div>
                                        <div className="form-control w-full">
                                            <label className="label cursor-pointer" style={{ justifyContent: "flex-end" }}>
                                                <input
                                                    type="radio"
                                                    name={`question-${key}`}
                                                    value="B"
                                                    className="radio checked:bg-blue-500"
                                                    checked={selectedValues[key] && selectedValues[key].answer === 'B'}
                                                    onChange={(e) => handleRadioChange(e, key)}
                                                    required
                                                />
                                                <span className="label-text m-2">B</span>
                                            </label>
                                        </div>
                                        <div className="form-control w-full">
                                            <label className="label cursor-pointer" style={{ justifyContent: "flex-end" }}>
                                                <input
                                                    type="radio"
                                                    name={`question-${key}`}
                                                    value="C"
                                                    className="radio checked:bg-blue-500"
                                                    checked={selectedValues[key] && selectedValues[key].answer === 'C'}
                                                    onChange={(e) => handleRadioChange(e, key)}
                                                    required
                                                />
                                                <span className="label-text m-2">C</span>
                                            </label>
                                        </div>
                                        <div className="form-control w-full">
                                            <label className="label cursor-pointer" style={{ justifyContent: "flex-end" }}>
                                                <input
                                                    type="radio"
                                                    name={`question-${key}`}
                                                    value="D"
                                                    className="radio checked:bg-blue-500"
                                                    checked={selectedValues[key] && selectedValues[key].answer === 'D'}
                                                    onChange={(e) => handleRadioChange(e, key)}
                                                    required
                                                />
                                                <span className="label-text m-2">D</span>
                                            </label>
                                        </div>
                                        <div className="form-control w-full">
                                            <label className="label cursor-pointer" style={{ justifyContent: "flex-end" }}>
                                                <input
                                                    type="radio"
                                                    name={`question-${key}`}
                                                    value="E"
                                                    className="radio checked:bg-blue-500"
                                                    checked={selectedValues[key] && selectedValues[key].answer === 'E'}
                                                    onChange={(e) => handleRadioChange(e, key)}
                                                    required
                                                />
                                                <span className="label-text m-2">E</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="modal-action ">
                            <button onClick={handleSubmit} className="w-full btn mt-2 mb-2 bg-[#120e43] rounded-[2px]">Submit</button>
                        </div>
                    </form>
                </label>
            </label>


            <Toaster/>
        </>
    )
}
export default OmrTest;

