import React from 'react';
import { CSVLink } from 'react-csv';
import {AiOutlineDownload} from "react-icons/ai";

const ConvertToCSV = ({ data, filename }) => {
	const convertToCSVData = () => {
		const csvData = [];

		const headers = Object.keys(data[0]);


		csvData.push(headers.map(header => header.toUpperCase()));


		data.forEach(item => {
			const row = headers.map(header => item[header]);
			csvData.push(row);
		});

		return csvData;
	};

	return (
		<CSVLink  data={convertToCSVData()} filename={filename}>
			<button  className="btn btn-sm btn-info mr-1 btn-outline !rounded-[2px]"><AiOutlineDownload className="mr-1"/> Result</button>
		</CSVLink>
	);
};

export default ConvertToCSV;
