import {RxCross2} from "react-icons/rx";
import React, {useRef, useState} from "react";
import {getUserData} from "../../utils/LocalHandler";
import axios from "axios";
import {API_BASE_URL} from "../../utils/config";

const Recording = () => {

	const closeBtnRef=useRef();
	const [channel,setChannel]=useState("0");
	const [dateTime,setDateTime]=useState()
	const [loading,setLoading]=useState(false)
	const [data,setData]=useState()


    async function handleRecording(e) {
		e.preventDefault()
		setLoading(true)
        const userDetails = getUserData();
        const token = userDetails.token;
        const configData = {
            token,
            channel_id:channel,
			date_time:dateTime
        };
        const response = await axios({
            url: `${API_BASE_URL}/get-recording`,
            method: "post",
            data: configData
        });
     	let data=await response
		if(data.data.recordingUrl.length>0 && data.data.status=='200'){
			setChannel(0)
			setDateTime(null)
			setData(data.data.recordingUrl)
		}
		else if(data.data.recordingUrl.length<=0){
			setData("error")
		}
        setLoading(false)
    }


    return(
		<>

			<input type="checkbox" id="recordingModal" className="modal-toggle" />
			<label htmlFor="recordingModal" className="modal cursor-pointer">
				<label className="modal-box pt-0 pb-0 relative" htmlFor="">
					<div className="grid grid-cols-2 mt-4">
						<div >
                            <p className="text-xl ">Get Recording</p>
						</div>
                        <div className="justify-self-end">
                            <label ref={closeBtnRef} htmlFor="recordingModal" className="btn btn-ghost btn-sm ">
                                <RxCross2/>
                            </label>
                        </div>
					</div>
					<hr/>
					<form className="text-center" onSubmit={handleRecording} >
						<div className="form-control ">
							<label className="label">
								<span className="label-text">Select Channel</span>
							</label>
                            <select
                                className="select w-full select-bordered c-font1 !rounded-[2px]"
                                value={channel}
                                onChange={(event) => {
                                    setChannel(event.target.value);
                                }}
                            >
                                <option value="0">Default Channel</option>
                                <option value="1">Channel 1</option>
                                <option value="2">Channel 2</option>
                                <option value="3">Channel 3</option>
                                <option value="4">Channel 4</option>

                            </select>
						</div>
						<div className="form-control ">
							<label className="label">
								<span className="label-text">Select Date & Time</span>
							</label>
							<input type="datetime-local" placeholder="Enter Action Statement" className="input rounded-[5px] input-bordered text-sm  !rounded-sm c-font1"
								   value={dateTime} onChange={(e)=>{setDateTime(e.target.value);}}
							required/>
						</div>
                        {
							loading?
                                <button className=" loading mt-2 mb-2 btn w-full bg-[#120e43] rounded-[2px]">Loading...</button>
								:
                                <button type="submit" className="mt-2 mb-2 btn w-full bg-[#120e43] rounded-[2px]">Submit</button>
						}

					</form>
                    {
                        data=="error"?
                            <div className="alert p-1 mt-4 mb-4 alert-error rounded-[2px] ">
                                <div>
                                    <span>Please check date time of Live Stream</span>
                                </div>
                            </div>
                            :data?
							<>
								<b>Recording Url:</b>
								<div className="alert w-full overflow-auto  p-1  mb-4 alert-success rounded-[2px]">
									<div>

										<span>{data}</span>
									</div>
								</div>
							</>
                               :null
                    }
				</label>
			</label>
		</>
	)

}
export default Recording
