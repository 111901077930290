import React, {useEffect, useState} from 'react';
import Papa from 'papaparse';
import { Link } from 'react-router-dom';
import { App_Name } from '../../../utils/name';
import axios from "axios";
import {getUserData} from "../../../utils/LocalHandler";
import {API_BASE_URL} from "../../../utils/config";
import toast, {Toaster} from "react-hot-toast";

const Result = () => {
	const [parsedData, setParsedData] = useState([]);
	const [tableRows, setTableRows] = useState([]);
	const [values, setValues] = useState([]);
	const [parsedDataArray, setParsedDataArray] = useState([]);

	const [loading,setLoading]=useState(false)
	const [result,setResult]=useState([])
	const [tab,setTab]=useState(1)

	const changeHandler = (event) => {
		Papa.parse(event.target.files[0], {
			header: true,
			skipEmptyLines: true,
			complete: function (results) {
				const parsedObjects = results.data.map((item) => {
					const modifiedItem = {};
					for (const key in item) {
						const newKey = key.toLowerCase().replace(/\s+/g, '_');
						modifiedItem[newKey] = item[key];
					}
					return modifiedItem;
				});

				// Save parsed data in the parsedData state
				setParsedData(parsedObjects);

				// Save parsed data in a separate state array
				setParsedDataArray([...parsedDataArray, ...parsedObjects]);

				const rowsArray = [];
				const valuesArray = [];

				parsedObjects.forEach((d) => {
					rowsArray.push(Object.keys(d));
					valuesArray.push(Object.values(d));
				});

				setTableRows(rowsArray[0]);
				setValues(valuesArray);
			},
		});
	};

	const handleUpload=async () => {
		setLoading(true)
		debugger
		try {
			const userDetails = getUserData();
			const token = userDetails.token;
			const response = await axios.post(`${API_BASE_URL}/upload-result`, {
				token: token,
				data: parsedDataArray,
			});

			const data=await response;
			debugger
			if(data.data.status == 200){
				setLoading(false)
				setValues(null)
				setTableRows(null)
				toast.success("Upload Completed")
			}
			else {
				toast.error("Failed to upload Please Refresh the page")
			}

		} catch (error) {

			console.error('Error uploading questions:', error.message);
		}
	}

	const getAll=async () => {
		try {
			const userDetails = getUserData();
			const token = userDetails.token;
			const response = await axios.post(`${API_BASE_URL}/get-all-result`, {
				token: token,
			});

			const data=await response;
			if(data.data.status == 200){
				setResult(data.data.data)
			}


		} catch (error) {

			console.error('Error uploading questions:', error.message);
		}
	}

	useEffect(() => {
		if(tab==2){
			getAll()
		}
	}, [tab]);
	return (
		<>
			<div className="bg-base-00 !p-4 m-0 flex flex-col h-screen">
				<header>
					<div className="mx-auto">
						<div className="grid grid-cols-3 max-sm:grid-cols-1 mb-2">
							<div className="">
								<Link
									to="/"
									className="mr-1 max-sm:ml-0 max-sm:mt-1 capitalize btn-warning btn !rounded-[2px] max-sm:text-sm max-sm:btn-sm"
								>
									{App_Name}
								</Link>
								<button className="btn bg-gray-300 btn-ghost !rounded-[2px] font-bold max-sm:text-sm max-sm:btn-sm">
									{'Result portal'}
								</button>
							</div>
							<div className="justify-self-end col-span-2 max-sm:mt-2">
								<div className="flex gap-2 items-center">
									<input
										className="file-input file-input-bordered rounded-[2px] file-input-sm"
										type="file"
										name="file"
										onChange={changeHandler}
										// accept=".csv"
									/>
									<button onClick={handleUpload} className="btn btn-accent btn-sm rounded-[2px]">Upload</button>
								</div>
							</div>
						</div>
					</div>
				</header>

				<div className="tabs bg-white mt-2 p-2 gap-2 tabs-boxed">
					<a className={`tab bg-base-200 tab-sm tab-bordered ${tab==1?"tab-active !bg-neutral":null}`}  onClick={()=>(
						setTab(1))}>Local Data</a>
					<a className={`tab bg-base-200 tab-sm tab-bordered ${tab==2?"tab-active !bg-neutral":null}`}  onClick={()=>(
						setTab(2))}>DB Data</a>
				</div>

				{

					tab==1?
						<>
							{
								!loading?
									<div className="overflow-auto w-screen">
										<table className="border-separate w-full table-zebra table table-compact ">
											<thead className="border-b sticky top-0">
											<tr>
												{tableRows?.map((rows, index) => {
													return <th className="bg-[#50DBB4] pl-2 pr-2 text-center" key={index}>{rows}</th>;
												})}
											</tr>
											</thead>
											<tbody>
											{values?.map((value, index) => {
												return (
													<tr key={index}>
														{value.map((val, i) => {
															return <td key={i}>{val}</td>;
														})}
													</tr>
												);
											})}
											</tbody>
										</table>
									</div>
									:
									<div className="hero min-h-screen bg-base-200">
										<div className="hero-content text-center">
											<div className="max-w-md">
												<h1 className="text-2xl font-bold ">
													Please Wait. Uploading...</h1>
											</div>
										</div>
									</div>
							}
						</>
						:
						<>
							<table className="border-separate w-full table-zebra table  table-compact ">
								<thead className="border-b sticky top-0">
								<tr>
									<th className="bg-[#50DBB4] text-center">Sr No</th>
									<th className="bg-[#50DBB4] text-center">Exam</th>
									<th className="bg-[#50DBB4] text-center">Date</th>
									<th className="bg-[#50DBB4] text-center">Phone</th>
									<th className="bg-[#50DBB4] text-center">Roll No</th>
									<th className="bg-[#50DBB4] text-center">Name</th>
									<th className="bg-[#50DBB4] text-center">Percentage</th>
									<th className="bg-[#50DBB4] text-center">Score</th>
									<th className="bg-[#50DBB4] text-center">Right Answers</th>
									<th className="bg-[#50DBB4] text-center">Wrong Answers</th>
									<th className="bg-[#50DBB4] text-center">Rank</th>
								</tr>
								</thead>
								<tbody>
								{
									result.length==0?
										<div className="h-screen w-screen">
											<p className="text-center mt-4">No data Found..</p>
										</div>:
										result?.map((e, id) => (
											<tr key={id} >
												<td>
													{
														id+1
													}
												</td>
												<td >
													{
														e.exam
													}
												</td>

												<td>
													{
e.exam_date
													}
												</td>
												<td >
													{
														e.phone
													}
												</td>
												<td >
													{
														e.roll_no
													}
												</td>
												<td>
													{e.student_name}
												</td>
												<td>
													{e.percentage}
												</td>
												<td>
													{e.score}
												</td>
												<td>
													{e.right_answers}
												</td>
												<td>
													{e.wrong_answers}
												</td><td>
													{e.student_rank}
												</td>
											</tr>
										)) }
								</tbody>
							</table>
						</>

				}

			</div>

			<Toaster/>
		</>
	);
};

export default Result;
