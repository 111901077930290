import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {App_Name} from "../../utils/name";
import toast, {Toaster} from "react-hot-toast";
import {getUserData} from "../../utils/LocalHandler";
import axios from "axios";
import {API_BASE_URL} from "../../utils/config";
import {AiFillCheckCircle} from "react-icons/ai";

const ErrorInQuestion = () => {

	const [search,setSearch]=useState("")

	const [data,setData]=useState([])


	async function fetchData() {
		const userDetails = getUserData();
		const token = userDetails.token;
		const configData = {
			token,
		};
		const response = await axios({
			url:`${API_BASE_URL}/get-data-error-in-q`,
			method: "post",
			data: configData
		});
		let data1 = response['data'];
		setData(data1.data);

	}

	useEffect(() => {
		fetchData()
	}, []);

	const handleResolve = async (id) => {
		const userDetails = getUserData();
		const token = userDetails.token;


		let options = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				token: token,
				id: id,
			}),
		};
		const response = await fetch(`${API_BASE_URL}/update-error-report`, options);
		let resData = await response.json();
		if (resData["status"] === 200) {
			toast.success("Resolved successfully")
			setData(resData.result)
		}
	};

	const [tab, setTab] = useState(1);
	const filteredItems = data.filter((qu) => {
		const lowerCaseSearch = search.toLowerCase();
		const quValues = Object.values(qu).map(value => value?.toString().toLowerCase());

		switch (tab) {
			case 1:
				return quValues.some(value => value?.toString().includes(lowerCaseSearch));
			case 2:
				return quValues.some(value => value?.toString().includes(lowerCaseSearch)) && qu.status !== 1;
			case 3:
				return quValues.some(value => value?.toString().includes(lowerCaseSearch)) && qu.status !== 0;
			default:
				return false;
		}
	});


	return(
		<>
			<div className="bg-base-00 !p-4 m-0 flex flex-col h-screen">
				<header>
					<div className="mx-auto">
						<div className="grid grid-cols-3 max-sm:grid-cols-1 mb-2">
							<div className="">
								<Link
									to="/"
									className="mr-1 max-sm:ml-0 max-sm:mt-1 capitalize btn-warning btn !rounded-[2px] max-sm:text-sm max-sm:btn-sm"
								>
									{App_Name}
								</Link>
								<button className="btn bg-gray-300 btn-ghost !rounded-[2px] font-bold max-sm:text-sm max-sm:btn-sm">
									{'Error Report'}
								</button>
							</div>
							<div className="justify-self-end col-span-2 max-sm:mt-2">
								<div className="flex gap-2 items-center">
									<input
										className="input input-bordered ml-auto m-1  !z-40 rounded-[2px] input-sm  "
										type="text"
										placeholder="Search"
										value={search}
										onChange={(event) => setSearch(event.target.value)}
									/>
								</div>
							</div>
						</div>
					</div>
				</header>

				<main className="flex-1 overflow-y-auto overflow-auto scrollbar-thin">
				<div className="tabs mt-2 p-2 gap-2 max-sm:mt-1 overflow-auto tabs-boxed">
					<a
						className={`tab bg-white max-sm:tab-sm tab-bordered ${tab == 1 ? "tab-active !bg-neutral" : null}`}
						onClick={() => setTab(1)}
					>
						All
					</a>
					<a
						className={`tab bg-white max-sm:tab-sm tab-bordered ${tab == 3 ? "tab-active !bg-neutral" : null}`}
						onClick={() => setTab(3)}
					>
						Solved
					</a>
					<a
						className={`tab bg-white max-sm:tab-sm tab-bordered ${tab == 2 ? "tab-active !bg-neutral" : null}`}
						onClick={() => setTab(2)}
					>
						Pending
					</a>
				</div>
						<div className="h-[90%] overflow-x-scroll overflow-y-scroll">
							<table className="border-separate w-full table-zebra table  table-compact ">
								<thead className="border-b sticky top-0">
								<tr>
									<th className="bg-[#50DBB4] text-center">Sr No</th>
									<th className="bg-[#50DBB4] text-center">Record ID</th>
									<th className="bg-[#50DBB4] text-center">Airtable Table</th>
									<th className="bg-[#50DBB4] text-center">Error</th>
									<th className="bg-[#50DBB4] text-center">Status</th>
									<th className="bg-[#50DBB4] text-center">Date</th>
									<th className="bg-[#50DBB4] text-center">Action</th>
								</tr>
								</thead>
								<tbody>
								{
									filteredItems.length==0?
										<div className="h-screen w-screen">
											<p className="text-center mt-4">No data Found..</p>
										</div>:
										filteredItems?.map((e, id) => (
											<tr key={id} >
												<td>
													{
														id+1
													}
												</td>
												<td >
													{
														e.record_id
													}
												</td>

												<td>
													{
														e.airtable_table_name
													}
												</td>

												<td>
													{
														e.error
													}
												</td>
												<td>
													{e.status == 1 ? (
														<span className="relative !z-0 inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
														<span
															aria-hidden
															className="absolute inset-0 bg-green-200 opacity-50 rounded-full"
														></span>
														<span className="relative">Solved</span>
													  </span>
																				) : (
																					<span className="relative !z-0 inline-block px-3 py-1 font-semibold text-red-900 leading-tight">
														<span
															aria-hidden
															className="absolute inset-0 bg-red-200 opacity-50 rounded-full"
														></span>
														<span className="relative">Pending</span>
													  </span>
													)}
												</td>
												<td>
													{
														e.date
													}
												</td>

												<td>
													<button
														className="btn btn-sm  btn-ghost text-2xl !rounded-[2px]"
														disabled={e.resolveStatus}
														onClick={() => handleResolve(e.id)}
													>
														<AiFillCheckCircle />
													</button>
												</td>
											</tr>
										)) }
								</tbody>
							</table>
						</div>
				</main>


			</div>

			<Toaster/>
		</>
	)
}
export default ErrorInQuestion;
